import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './Calendario.css';

export default function Calendario() {
  return (
    <>
      <Header />
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Calendário Acadêmico</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <p className='center-text'>
          O Calendário Acadêmico é a normalização de todos os prazos e deveres
          da Instituição, em relação à atividade docente/discente. Segue abaixo
          o Calendário Acadêmico.
        </p>
        <div className='calendario-div'>
          <a className='calendario-link'
            href="/assets/calendario_academico_2025.1.pdf">
            Faça o download do Calendário Acadêmico
          </a>
        </div>

        <iframe
          src="/assets/calendario_academico_2025.1.pdf"
          width="100%"
          height="500px"
        ></iframe>
      </div>
      <Footer />
    </>
  );
}
